//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data(){
		return {
			dataarr:[
				{
					title1:require('../../assets/image/zhaosi.jpg'),
					title2:'气门挺柱',
					title3:'1234567890',
					title4:'咨询描述咨询描述咨询描述咨询描述咨询描述咨询描述',
					title5:'副厂配件',
				},
				{
					title1:require('../../assets/image/zhaosi.jpg'),
					title2:'气门挺柱',
					title3:'1234567890',
					title4:'咨询描述咨询描述咨询描述咨询描述咨询描述咨询描述',
					title5:'副厂配件',
				},
				{
					title1:require('../../assets/image/zhaosi.jpg'),
					title2:'气门挺柱',
					title3:'1234567890',
					title4:'咨询描述咨询描述咨询描述咨询描述咨询描述咨询描述',
					title5:'副厂配件',
				},
				{
					title1:require('../../assets/image/zhaosi.jpg'),
					title2:'气门挺柱',
					title3:'1234567890',
					title4:'咨询描述咨询描述咨询描述咨询描述咨询描述咨询描述',
					title5:'副厂配件',
				},
				{
					title1:require('../../assets/image/zhaosi.jpg'),
					title2:'气门挺柱',
					title3:'1234567890',
					title4:'咨询描述咨询描述咨询描述咨询描述咨询描述咨询描述',
					title5:'副厂配件',
				}
			],
			currentPage: 1,
		}
	},
	methods:{
		edit(){
			this.$router.push('consulting-detaile')
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
		},
	}
}
